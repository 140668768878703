.leaflet-container {
    overflow: visible !important;
}

.rotate45 {
    transform: rotate(45deg) !important;
}

.overflow-hidden {
    overflow: hidden;
}

.rotate-and-space {
    /* transform: rotate(45deg) !important; */
    /* width: 1000%; */
}