body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.xgrid .tick line {
  stroke: #8E8883;
}
.ygrid .tick line {
  stroke: #8E8883;
}

.wi {
  color: rgb(252, 187, 24);
  font-size: 1.5em;
}

.mapMarkerTooltip {
  position: absolute;
  font-size: 18px;
  opacity: 0;
  background-color: white;
  border: solid;
  border-width: 2px;
  border-radius: 5px;
  padding: 5px;
  stroke: black;
  left: 0px;
  top: 0px;

}

/* * {
  outline: 1px solid red;
} */
